





















































































































































































import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import { usePost, PostProperty, PostProps, postLabels } from '@/admin/talkRoom';
import { useNotification } from '@/composition/notification';
import { downloadCsv } from '@/admin/util';
import { useConfirm } from '@/composition/confirm';
import xss from '@/composition/xss';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import myAttributes from '@/composition/myAttributes';
import FcWysiwyg from '@/components/FcWysiwyg.vue';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import { useWysiwygSetting } from '@/composition/wysiwygSetting';

interface ConfirmProperty {
  postId: string;
  nickname: string;
  body: string;
}
export default defineComponent({
  name: 'TalkRoomPost',
  components: {
    FcImage,
    FcStaticImage,
    FcWysiwyg,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    talkRoomId: {
      type: String,
      default: '',
    },
  },
  setup({ talkRoomId }) {
    const wysiwygSetting = useWysiwygSetting();
    wysiwygSetting.fetchConsoleMenu();
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('talkrooms'));
    const isLinkedOfficialUser = (id: string) => {
      return myAttributes.linkedOfficialUserIds.includes(id);
    };

    const expanded = ref([]);
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const formData: Ref<PostProps | null> = ref(null);
    const confirmData: Ref<ConfirmProperty | null> = ref(null);
    const active = ref(false);
    const isConfirm = ref(false);

    const { post } = usePost(talkRoomId);
    const headers = [
      { text: 'ピン留め', value: 'pin', align: 'center', width: '70px', class: 'px-0' },
      { text: 'ニックネーム', value: 'nickname', width: '250px', align: 'start' },
      { text: '投稿内容', value: 'postedDate.dateTime' },
      { text: '投稿画像', value: 'image' },
      { text: '投稿動画', value: 'videoId' },
      { text: '', value: 'actions', width: '60px', sortable: false, align: 'end' },
    ];
    const footerProps = {
      itemsPerPageText: '表示件数',
      itemsPerPageOptions: [100, 200, 500, 1000, { text: 'すべて', value: -1 }],
    };
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    const createPost = () => {
      if (post.officialUsers.length === 0) {
        notification.error('投稿作成には公式ユーザーを少なくとも1人は設定してください');
        return;
      }
      formData.value = {
        talkRoomId,
        body: '',
        officialUserId: post.officialUsers[0].officialUserId,
        image: '',
        videoId: '',
      };
      active.value = true;
    };
    const savePin = async (postId: string) => {
      if (
        !(await confirmDialog(
          `${
            postId === post.pinnedPostId
              ? '本当にピン留めを削除しますか？'
              : 'ピン留めは1つだけです。本当に投稿をピン留めしますか？'
          }`
        ))
      )
        return;
      post.pinnedLoading = true;
      if (postId === post.pinnedPostId) {
        try {
          await post.deletePinnedPost();
          notification.notify('ピン留めを削除しました。');
        } catch (error) {
          notification.error(error);
        }
      } else {
        try {
          await post.savePinnedPost(postId);
          notification.notify('ピン留めしました。');
        } catch (error) {
          notification.error(error);
        }
      }
      post.pinnedLoading = false;
    };
    const save = async () => {
      if (!(await confirmDialog('本当に投稿を保存しますか？'))) return;
      if (!formData.value) return;
      if (formData.value.body === '' && formData.value.image === '' && formData.value.videoId === '') {
        notification.error('投稿内容、投稿画像、投稿動画のいずれかは必須です。');
        return;
      }
      try {
        await post.post(formData.value);
        notification.notify('作成しました。');
      } catch (error) {
        notification.error(error);
      }
      active.value = false;
    };
    const cancel = () => {
      active.value = false;
      isConfirm.value = false;
    };
    const deleteConfirm = (item: PostProperty) => {
      if (post.pinnedPostId === item.postId) {
        alert('ピン留めを解除してください');
        return;
      }
      isConfirm.value = true;
      confirmData.value = {
        postId: item.postId,
        nickname: item.nickname,
        body: item.body,
      };
    };
    const deletePost = async () => {
      if (!confirmData.value) return;
      try {
        await post.deletePost(confirmData.value.postId);
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
      isConfirm.value = false;
    };
    // ダウンロードボタン
    const download = async () => {
      try {
        const attrHeaders: DataTableHeader[] = Object.entries(postLabels).map(([key, value]) => ({
          text: value,
          value: key,
        }));
        const items = await post.getCsvData();
        downloadCsv(attrHeaders, items, 'talkroom-posts.csv');
      } catch (error) {
        notification.error(error);
      }
    };

    return {
      pageTitle: 'トークルーム',
      wysiwygSetting,
      myRoleSettings,
      isLinkedOfficialUser,
      expanded,
      xss,
      headers,
      post,
      footerProps,
      tableHeight,
      createPost,
      cancel,
      save,
      deleteConfirm,
      deletePost,
      active,
      formData,
      isConfirm,
      confirmData,
      savePin,
      download,
    };
  },
});
